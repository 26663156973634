.site-marketing-messaging-nav {
	@apply tw-hidden;

	@screen md {
		@apply tw-border tw-border-transparent tw-flex tw-h-[24px] tw-items-center tw-justify-center tw-rounded-full tw-transition-colors tw-w-[24px] tw-z-10;
	}

	&:before {
		@apply tw-border-white tw-content-[''] tw-h-[8px] tw-rotate-45 tw-w-[8px];
	}

	&:focus, &:hover {
		@apply tw-border-white;
	}
}

.site-marketing-messaging-nav-BANNER_BLACK {
	@apply tw-bg-gray;
}

.site-marketing-messaging-nav-BANNER_RED {
	@apply tw-bg-red;
}

.site-marketing-messaging-nav-NEXT {
	&:before {
		@apply tw-border-r tw-border-t tw-translate-x-[-2px];
	}
}

.site-marketing-messaging-nav-PREV {
	&:before {
		@apply tw-border-b tw-border-l tw-translate-x-[2px];
	}
}
